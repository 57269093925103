import { useDraftStore } from '~/stores/draft'
import { useUserStore } from '~/stores/user'
import { useUserFavoritesStore } from '~/stores/userFavorites'

/**
 * Fetches the cart, the favorites and the draft for the currently logged in user.
 */
export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const draftStore = useDraftStore($pinia)
  const userFavoritesStore = useUserFavoritesStore($pinia)
  const promiseArray = []

  promiseArray.push(draftStore.RETRIEVE_DRAFT_FROM_LOCAL_STORAGE())

  if (!userStore.IS_BAND) {
    await Promise.all(promiseArray)
    return
  }

  promiseArray.push(userFavoritesStore.FETCH_BUCKETS())
  promiseArray.push(draftStore.GET_CURRENT())
  await Promise.all(promiseArray)
})
